<template>
    <v-container>
        <v-row no-gutters>
            <v-expansion-panels multiple>
                <v-expansion-panel v-for="sh in schemes" :key="sh.id">
                    <v-expansion-panel-header>
                        <v-row justify="space-between" no-gutters>
                            <span>
                                <b>{{ sh.name }}:</b> {{ sh.description }}
                            </span>
                            <v-card flat>
                                ЖВНЛП: <b>{{ sh.zvnlp ? "ДА" : "НЕТ" }}</b>
                            </v-card>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-expansion-panels multiple>
                            <v-expansion-panel
                                v-for="drug in sh.drugs"
                                :key="drug.id"
                            >
                                <v-expansion-panel-header>
                                    <v-row justify="space-between" no-gutters>
                                        <span>
                                            <b>{{ drug.name }}</b>
                                        </span>
                                        <v-card flat>
                                            ЖВНЛП:
                                            <b>
                                                {{ drug.zvnlp ? "ДА" : "НЕТ" }}
                                            </b>
                                        </v-card>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table
                                        :headers="packHeaders"
                                        :items="[drug.pack]"
                                        dense
                                        disable-pagination
                                        hide-default-footer
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row justify="center" no-gutters>
            <v-btn @click="getExcel">Выгрузка EXCEL</v-btn>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PlainSchemesResults",
    data: () => ({
        schemes: [],
        packHeaders: [
            {
                text: "Наименование",
                align: "start",
                sortable: false,
                value: "name"
            },
            { text: "Упаковка", value: "description" },
            { text: "Лек. форма", value: "form" },
            { text: "Объём упаковки", value: "size" },
            { text: "Цена упаковки", value: "price" },
            { text: "Кол-во упаковки", value: "amount" },
            { text: "Стоимость", value: "total_price" },
            { text: "Кол-во упаковки (округл.)", value: "amount_rounded" },
            { text: "Стоимость (округл.)", value: "total_price_rounded" }
        ]
    }),
    mounted() {
        this.axios
            .post(
                `/api/schemes-plain-data`,
                this.$store.state.test.plainSchemes
            )
            .then(resp => {
                this.schemes = resp.data;
            });
    },
    methods: {
        getExcel() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/schemes-plain-excel`, {
                    responseType: "blob",
                    params: { schemes: this.$store.state.test.schemes }
                })
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>

<style scoped></style>
